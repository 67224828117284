<template>
    <v-row>
        <v-col cols="12" lg="7" xl="6" class="leftside d-none d-md-flex align-center justify-center position-relative">
            <v-container>
                <div class="pa-10">
                    <v-row justify="center">
                        <v-col cols="8" xl="5">
                            <div>
                                <h2
                                    class="display-1 white--text font-weight-medium"
                                >CPAider</h2>
                                <h6
                                    class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                                >{{ trans('pages.auth.authText') }}</h6>
                                <v-btn class="mt-4 text-capitalize" x-large outlined color="white" :href="backendUrl">{{ trans('buttons.common.home') }}</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px;">
            </v-container>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
            <v-container>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px; max-width: 70px" class="d-block d-md-none">
                <div class="pa-7 pa-sm-12">
                    <v-row>
                        <v-col cols="12">
                            <lang-switcher></lang-switcher>
                        </v-col>
                        <v-col cols="12" lg="9" xl="6" class="text-center">
                            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">{{ trans('pages.auth.signUp') }}</h2>
                            <h6 class="subtitle-1">
                                {{ trans('pages.register.haveAccount') }}
                                <router-link to="login">{{ trans('pages.auth.signIn') }}</router-link>
                            </h6>

                            <v-form ref="form" lazy-validation action="/pages/boxedlogin">
                                <v-text-field
                                    v-model="email"
                                    :label="trans('pages.fields.email')"
                                    class="mt-4"
                                    :error-messages="errors.email"
                                    required
                                    outlined
                                ></v-text-field>
                                <v-text-field
                                    v-model="password"
                                    :label="trans('pages.fields.password')"
                                    :error-messages="errors.password"
                                    required
                                    outlined
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show1 ? 'text' : 'password'"
                                ></v-text-field>

                                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                                    <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || trans('pages.auth.agree')]"
                                        :label="trans('pages.fields.agreeTerms')"
                                        required
                                    >
                                        <template slot="append">
                                            <a target="_blank" :href="route('policy', $store.getters.curlocale)">{{ trans('pages.fields.termsText') }}</a>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <v-btn
                                    color="info"
                                    block
                                    class="mr-4"
                                    submit
                                    @click="submit"
                                >{{ trans('pages.buttons.signUp') }}</v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import LangSwitcher from "@/components/commonComponents/LangSwitcher";
    const axios = window.axios;
    const env = require('@/env.json');
    export default {
        name: "FullLogin",
        components: {LangSwitcher},
        data: () => ({
            password: "",
            show1: false,
            passwordRules: [
                v => !!v || "Password is required",
                v => (v && v.length >= 7) || "Password must be less than 10 characters"
            ],
            email: "",
            emailRules: [
                v => !!v || "E-mail is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            checkbox: false,
            errors: {},
        }),
        computed: {
            backendUrl() {
                return env.backendUrl;
            }
        },
        methods: {
            submit() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    this.errors = {};
                    axios.post(this.route('register'), {
                        email: this.email,
                        password: this.password,
                    }).then( () => {
                        this.$store.dispatch('reachGoal', 'userRegistered');
                        this.$store.dispatch('getAuth').then( () => {
                            if (this.$store.getters.isAdmin){
                                this.$router.addRoutes(require('@/routerAdmin').default)
                            }
                            this.$router.addRoutes(require('@/routerUser').default)
                            this.$router.push({ name: this.$store.getters.homeRouteName });
                        });
                    }).catch( errors => {
                        this.errors = errors.response.data.errors;
                    })
                }
            }
        }
    };
</script>
<style scoped>
    .leftside {
        background: url("../../assets/images/web-web.svg") no-repeat center right;
    }
</style>
