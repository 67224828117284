<template>
    <div>
        <span class="setLocale" @click="changeLocale('ru')">RU </span><span class="setLocale" @click="changeLocale('en')"> EN</span>
    </div>
</template>

<script>
    export default {
        name: "LangSwitcher",
        data() {
            return {

            };
        },
        methods: {
            changeLocale(locale){
                this.$vuetify.lang.current = locale;
                this.$store.dispatch('forceLocale', locale);
            }
        }
    }
</script>
<style scoped>
    .setLocale {
        cursor: pointer;
    }
</style>
